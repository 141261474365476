import headerImage from './assets/images/header-image.jpg'

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './App.css';

import HeaderImageText from './components/Header/Header'
import MarkdownBlock from './components/MarkdownBlock/MarkdownBlock';
import FileDropZone from './components/FileDropZone/FileDropZone';
import TableAction from './components/TableAction/TableAction';
import { safeRetrieve } from './utils/safeStoring';
import { makeToast } from './utils/makeToast';
import { callCreateTele24 } from './utils/createTele24';
import { sPullFile } from './utils/sPullFile';
import brandLogo from './assets/images/brand-logo.svg';

import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/react/tab-group';
import SlTabPanel from '@shoelace-style/shoelace/dist/react/tab-panel';

import { useMediaQuery } from "react-responsive";


import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/themes/dark.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

// override with custom styles
import './Styles.css';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/');


function App() {
  
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [apiKey, setApiKey] = useState(safeRetrieve('APIKEY'));

  const [uploadedFiles, setUploadedFiles] = useState([
    // {"fileName": "This is a long title for a document.docx", 's3Key': '', 'uploaded': false, 'deleting': false}
  ]);
  const [uploadedLedger, setUploadedLedger] = useState([
    // {"fileName": "This is a long title for a document.docx", 's3Key': '', 'uploaded': false, 'deleting': false}
  ]);
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [telematicFiles, setTelematicFiles] = useState([]);

  const systemDarkMode = useMediaQuery({ query: '(prefers-color-scheme: dark)' });

  // Initialize dark mode state  
  useEffect(() => {
    const appThemeCookies = Cookies.get('appTheme') || ''
    if (appThemeCookies === 'light') {
      setIsDarkMode(false)
    } else if (appThemeCookies === 'dark') {
      setIsDarkMode(true)
    } else {
      setIsDarkMode(systemDarkMode)
    }

    
  }, [systemDarkMode]);


  const toggleDarkMode = (checked) => {
    setIsDarkMode(checked);
    Cookies.set('appTheme', checked ? 'dark' : 'light');
  };


  const callExtractor = () => {

    // check if a request is already running
    if (isWaitingResponse) {
      return;
    }

    // check if there are files to send
    if (uploadedFiles.length === 0) {
      return;
    }

    // check if all files are uploaded
    if (!uploadedFiles.every(file => file.uploaded)) {
      return;
    }

    setTelematicFiles([]);
    setIsWaitingResponse(true);

    const apiUrl = process.env.REACT_APP_API_URL || '';
    fetch(`${apiUrl}/api/extractor/v1/extract_pdf`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          api_key: apiKey,
          files_s3_keys: uploadedFiles.map(file => file.s3Key)
      }),
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((errorData) => {
          console.error(`Error in extract_pdf response: ${response.status}`, errorData);
          makeToast(`C'è stato un errore nel processo di estrazione, per favore controlla che il documento caricato sia conforme ai requisiti. Se necessario, contatta l'amministratore di sistema.`, 'warning');
          setIsWaitingResponse(false);
        });
      }
      return response.json();  // Proceed to parse JSON if the response is OK
    })
    .then((data) => {
      const extractionResult = data.body;
      console.log(extractionResult);
      
      // loop over the extraction results and call the tele24 traduction, storing the result in a new list
      // Use Promise.all to wait for all async operations to complete
      Promise.all(extractionResult.map(el => callCreateTele24(el)))
      .then(newTelematicFiles => {
        console.log(newTelematicFiles);
        const goodTelematicFiles = newTelematicFiles.filter(item => item !== undefined);
        console.log(goodTelematicFiles);
        setTelematicFiles(goodTelematicFiles);
        setIsWaitingResponse(false);
        // scroll to bottom of the page
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      })
      .catch(error => {
        console.error('Error in processing telematic files:', error);
        makeToast('Si è verificato un errore di rete. Per favore riprova più tardi.', 'warning');
        setIsWaitingResponse(false);
      });
    })
    .catch((error) => {
      console.error('Request failed:', error);
      makeToast('Si è verificato un errore di rete. Per favore riprova più tardi.', 'warning');
      setIsWaitingResponse(false);
    });
  };


  const callOrdimastro = () => {

    // check if a request is already running
    if (isWaitingResponse) {
      return;
    }

    // check if there are files to send
    if (uploadedLedger.length === 0) {
      return;
    }

    // check if all files are uploaded
    if (!uploadedLedger.every(file => file.uploaded)) {
      return;
    }

    setIsWaitingResponse(true);

    const apiUrl = process.env.REACT_APP_API_URL || '';
    fetch(`${apiUrl}/api/ordimastro/v1/clean_ledger`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          api_key: apiKey,
          files_s3_keys: uploadedLedger.map(file => file.s3Key)
      }),
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((errorData) => {
          console.error(`Error in clean_ledger response: ${response.status}`, errorData);
          makeToast(`C'è stato un errore nel processo di conversione, per favore controlla che il documento caricato sia conforme ai requisiti. Se necessario, contatta l'amministratore di sistema.`, 'warning');
          setIsWaitingResponse(false);
        });
      }
      return response.json();  // Proceed to parse JSON if the response is OK
    })
    .then((data) => {
      const resultKey = data.body;
      console.log(resultKey);
      sPullFile(resultKey);
      setIsWaitingResponse(false);
    })
    .catch((error) => {
      console.error('Request failed:', error);
      makeToast('Si è verificato un errore di rete. Per favore riprova più tardi.', 'warning');
      setIsWaitingResponse(false);
    });
  };



  return (
    <div className={isDarkMode ? 'dark-mode sl-theme-dark sl-theme-dark-fatturami' : 'sl-theme-list sl-theme-fatturami'} style={{ height: 'inherit', minHeight: 'inherit' }}>

      <div className="App">

        <div>
        
          <header className="App-header">
            <HeaderImageText 
              imageSrc={headerImage} 
              imageAltText="Header cover image showing a city covered by nature" 
              title="FatturaMI" 
              descriptionFilePath="/docs/description-page.md"  // path relative to /public
              isDarkMode={isDarkMode}
              toggleDarkMode={toggleDarkMode}
              setApiKey={setApiKey}
            />
          </header>

          <div className="App-body">
            <div className="demo-content">

              <SlTabGroup className="navigation-tabs" id="navigation-tabs">

                <SlTab slot="nav" panel="f24-per-datev">
                  <div className="tab-title">
                    <SlIcon name="cash-coin" />
                    F24 per Datev
                  </div>
                </SlTab>
                <SlTab slot="nav" panel="clear-ledger">
                  <div className="tab-title">
                    <SlIcon name="file-earmark-spreadsheet-fill" />
                    Clear Ledger
                  </div>
                </SlTab>

                <SlTabPanel name="f24-per-datev">

                  <MarkdownBlock 
                    filePath="/docs/description-f24.md"  // path relative to /public
                    detailsTitle="Istruzioni"
                  />

                  <FileDropZone 
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    useCaseName="tele24"
                  />
                  
                  <SlButton 
                    variant="primary"
                    size="large"
                    pill
                    className="btn-primary"
                    loading={isWaitingResponse}
                    onClick={callExtractor}
                    disabled={!(uploadedFiles.every(file => file.uploaded)) || (uploadedFiles.length === 0)}
                  >
                    <SlIcon slot="prefix" name="magic" style={{ fontSize: "16pt" }}></SlIcon>
                    Crea documento telematico
                  </SlButton>

                  {telematicFiles && (telematicFiles.length > 0) && (
                    <TableAction 
                      tableData={telematicFiles}
                    />
                  )}
                  
                </SlTabPanel>

                <SlTabPanel name="clear-ledger">

                  <MarkdownBlock 
                    filePath="/docs/description-ledger.md"  // path relative to /public
                    detailsTitle="Istruzioni"
                  />
                  
                  <FileDropZone 
                    uploadedFiles={uploadedLedger}
                    setUploadedFiles={setUploadedLedger}
                    useCaseName="ordimastro"
                  />
                  
                  <SlButton 
                    variant="primary"
                    size="large"
                    pill
                    className="btn-primary"
                    loading={isWaitingResponse}
                    onClick={callOrdimastro}
                    disabled={!(uploadedLedger.every(file => file.uploaded)) || (uploadedLedger.length === 0)}
                  >
                    <SlIcon slot="prefix" name="magic" style={{ fontSize: "16pt" }}></SlIcon>
                    Crea Ledger ripulito
                  </SlButton>

                </SlTabPanel>
              
              </SlTabGroup>

            </div>
          </div>
        
        </div>

        <footer>
          <img src={brandLogo} alt='STSMI logo' style={{ height: "-webkit-fill-available" }} />
          <span>Copyright © 2024</span>
        </footer>
      
      </div>
    
    </div>
  );
}

export default App;
