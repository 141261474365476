import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import './Header.css';

import ImageWithFadingBottom from "../ImageFading/ImageFading";
import LoginDialog from '../LoginDialog/LoginDialog';
import MarkdownBlock from '../MarkdownBlock/MarkdownBlock';
import { safeRetrieve } from '../../utils/safeStoring';

import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';


const HeaderImageText = ({ imageSrc, imageAltText, title, descriptionFilePath, isDarkMode, toggleDarkMode, setApiKey }) => {
  
  const [showLoginDialog, setShowLoginDialog] = useState(safeRetrieve('APIKEY').length === 0);

  
  return (
    <>

      <div className="actions-group" style={{ right: "20px "}}>
        
        <div>
          <DarkModeSwitch
            checked={isDarkMode}
            onChange={toggleDarkMode}
            size={24}
          />
        </div>

        <SlButton className="top-appbar-btn" size="medium" pill onClick={() => setShowLoginDialog(true)}>
          <SlIcon slot="suffix" name="person-circle" style={{ fontSize: "14pt" }} />
          {Cookies.get('USERNAME') || "Cambia utente"}
        </SlButton>

      </div>


      <LoginDialog 
        isOpen={showLoginDialog} 
        setIsOpen={setShowLoginDialog} 
        setApiKey={setApiKey}
      />
      
      <ImageWithFadingBottom 
        imageSrc={imageSrc} 
        altText={imageAltText} 
        fadeColor="var(--surface)"
      />
      <div className="header-image-text-title">
        <img src='/logo512.png' alt='website logo' style={{ height: "max(7vw, 32pt)" }} /> {title}
        {/* <span>{title}</span> */}
      </div>

      <div style={{ maxWidth: '75%' }} >
        <MarkdownBlock 
          filePath={descriptionFilePath}
          detailsTitle=""
        />
      </div>

    </>
  );
};

export default HeaderImageText;
