import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import './MarkdownBlock.css';

import { useMediaQuery } from "react-responsive";

import SlDetails from '@shoelace-style/shoelace/dist/react/details';


const MarkdownBlock = ({ filePath, detailsTitle }) => {
  
  const [markdownText, setMarkdownText] = useState('');
  
  const isSmallScreen = useMediaQuery({ maxWidth: 500 });
  
  
  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(filePath); // Assuming the file path is a URL
        const text = await response.text();
        setMarkdownText(text);
      } catch (error) {
        console.error('Error fetching Markdown:', error);
      }
    };

    fetchMarkdown();
  }, [filePath]);
  

  return (
    <>
      {(isSmallScreen && detailsTitle.length > 0) ? (
        <SlDetails className="markdown-block-details" summary={detailsTitle} >
          <ReactMarkdown className="markdown-block-text">{markdownText}</ReactMarkdown>
        </SlDetails>
      ) : (
        <ReactMarkdown className="markdown-block-text">{markdownText}</ReactMarkdown>
      )}
    </>
  );
};

export default MarkdownBlock;
