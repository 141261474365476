import { encrypt, decrypt } from './crypto';


export const safeStore = (key, value) => {
  const encryptedValue = encrypt(value);
  localStorage.setItem(key, encryptedValue);
};

export const safeRetrieve = (key) => {
  const encryptedValue = localStorage.getItem(key);
  return encryptedValue ? decrypt(encryptedValue) : '';
};
