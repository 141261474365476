import { safeRetrieve } from './safeStoring';
import { makeToast } from './makeToast';


export const sPullFile = (s3Key) => {

    const apiKey = safeRetrieve('APIKEY');
    
    const apiUrl = process.env.REACT_APP_API_URL || '';
    fetch(`${apiUrl}/api/tele24/v1/spull_file?api_key=${apiKey}&s3_key=${s3Key}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => {
        if (!response.ok) {
            return response.json().then((errorData) => {
                console.error(`Error in spull_file response: ${response.status}`, errorData);
                makeToast(`C'è stato un errore nel download del file. Per favore contatta l'amministratore di sistema.`, 'warning');
                return;
            });
        }
        return response.json();  // Proceed to parse JSON if the response is OK
    })
    .then((data) => {
        // create a link element to trigger the download
        const a = document.createElement('a');
        a.href = data.body;
        document.body.appendChild(a);

        // click the link and then remove it
        a.click();
        a.remove();
    })
    .catch((error) => {
        console.error('Request failed:', error);
        makeToast('Si è verificato un errore di rete. Per favore riprova più tardi.', 'warning');
    });

}
