import React, { useState } from 'react';
import Cookies from 'js-cookie';
import './LoginDialog.css';

import { safeRetrieve, safeStore } from '../../utils/safeStoring';
import { makeToast } from '../../utils/makeToast';

import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlButton from '@shoelace-style/shoelace/dist/react/button';


function LoginDialog ({ isOpen, setIsOpen, setApiKey }) {

    const [isLoading, setIsLoading] = useState(false);
    const [inputText, setInputText] = useState(safeRetrieve('APIKEY'));
    const [userName, setUserName] = useState('');
    const [messageSuccess, setMessageSuccess] = useState(false);
    const [messageError, setMessageError] = useState(false);

    const handleClose = (event) => {
        if (safeRetrieve('APIKEY').length === 0) {
            // prevent closing the dialog
            event.preventDefault()
        } else {
            // close dialog
            setIsOpen(false);
            // reset messages
            setMessageSuccess(false);
            setMessageError(false);
        }
    }

    const getUserInfo = () => {
        // get API key from cookies
        const apiKey = safeRetrieve('APIKEY');
        // call API to get user name from API key
        const apiUrl = process.env.REACT_APP_API_URL || '';
        const url = `${apiUrl}/api/tele24/credentials/user_info?api_key=${apiKey}`;
        fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((errorData) => {
                    console.error(`Error in credentials/user_info response: ${response.status}`, errorData);
                    makeToast(`C'è stato un errore durante l'accesso. Per favore contatta l'amministratore di sistema.`, 'warning');
                });
            }
            return response.json();  // Proceed to parse JSON if the response is OK
        })
        .then((data) => {
            setUserName(data.body.user_name);
            Cookies.set('USERNAME', data.body.user_name);
        })
        .catch((error) => {
            console.error('Request failed:', error);
            makeToast('Si è verificato un errore di rete. Per favore riprova più tardi.', 'warning');
        });
    }


  const handleLogin = () => {
    // start loading animation on button
    setIsLoading(true);
    setMessageSuccess(false);
    setMessageError(false);

    setTimeout(() => {
        // call API for validation
        const apiUrl = process.env.REACT_APP_API_URL || '';
        const url = `${apiUrl}/api/tele24/credentials/validate?api_key=${inputText}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((errorData) => {
                        console.error(`Error in credentials/validate response: ${response.status}`, errorData);
                        makeToast(`C'è stato un errore durante l'accesso. Per favore contatta l'amministratore di sistema.`, 'warning');
                        setIsLoading(false);
                    });
                }
                return response.json();  // Proceed to parse JSON if the response is OK
            })
            .then((data) => {
                if (data.body) {
                    // update using method from App.js to launch the corresponding useEffect
                    setApiKey(inputText)
                    // save api key in cookies
                    safeStore('APIKEY', inputText);
                    // get user-name from API key and store it in cookies
                    getUserInfo();
                    // show success message
                    setMessageSuccess(true);
                    setTimeout(() => {
                        // close dialog
                        handleClose();
                    }, 1500);
                } else {
                    setMessageError(true);
                }
                // stop loading animation on button
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Request failed:', error);
                makeToast('Si è verificato un errore di rete. Per favore riprova più tardi.', 'warning');
                setIsLoading(false);
            });
    }, 1000);    
  }


  return (
    <>
    <SlDialog 
        label="Accedi" 
        open={isOpen} 
        onSlRequestClose={(e) => handleClose(e)}
        style={{ 'color': 'var(--on-surface)' }}
    >

        <div className="dialog-body">

            Inserisci la tua API key per accedere alla piattaforma. Se non hai le credenziali di accesso, contatta l'amministratore di sistema.

            <SlInput 
                placeholder="Inserisci qui la tua API key"
                password-toggle
                type="password"
                value={inputText}
                onSlInput={(e) => setInputText(e.target.value)}
            >
                <SlIcon name="key" slot="prefix"></SlIcon>
            </SlInput>

            {messageSuccess && (
                <div className='message-success'>
                    <SlIcon name="check2-circle"></SlIcon>
                    <div>Credenziali valide! Benvenuto {userName}</div>
                </div>
            )}
            {messageError && (
                <div className='message-error'>
                    <SlIcon name="x-circle"></SlIcon>
                    <div>Le credenziali inserite non sono valide.</div>
                </div>
            )}
        
        </div>

        <SlButton 
            className="btn-login"
            slot="footer" 
            variant="primary" 
            loading={isLoading}
            onClick={() => handleLogin()}
        >
            {safeRetrieve('APIKEY') ? "Cambia utente" : "Accedi alla piattaforma"}
        </SlButton>
    
    </SlDialog>
    
    </>
  );
};


export default LoginDialog;
