import React, { useState } from 'react';
import './TableAction.css'

import { sPullFile } from '../../utils/sPullFile';

import SlIconButton from '@shoelace-style/shoelace/dist/react/icon-button';


const formatDate = (dateString) => {
  // Ensure the input is a string of the correct length
  if (dateString.length !== 8) {
    return 'Invalid date format';
  }

  const day = dateString.slice(0, 2);
  const month = dateString.slice(2, 4);
  const year = dateString.slice(4, 8);

  return `${day}/${month}/${year}`;
};


function TableAction ({ tableData }) {
  
  const [hoveringButton, setHoveringButton] = useState(-1);

  return (
    <div className="table-action-group">

      {(tableData.length === 1) ? (
        <span>É stato trovato <strong>1</strong> versamento</span>
      ) : (
        <span>Sono stati trovati <strong>{tableData.length}</strong> versamenti</span>
      )}
      
      <div className="table-container">
      <table className="table-action">
        
        <thead>
          <tr className="table-action-header" >
            <th>C.F. contribuente</th>
            <th>Data pagamento</th>
            <th></th>
          </tr>
        </thead>
        
        <tbody>

          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex} className="table-action-row" >

              {/* contribuente */}
              <td>
                  {row.cfContribuente}
              </td>
              
              {/* payment date */}
              <td>
                  {formatDate(row.paymentDate)}
              </td>

              {/* download button */}
              <td style={{ fontSize: '16pt' }} >
                <SlIconButton 
                  className="table-action-btn"
                  name={`${(hoveringButton === rowIndex) ? 'arrow-down-square-fill' : 'arrow-down-square'}`}
                  onClick={() => sPullFile(row.s3Key)}
                  onMouseEnter={() => setHoveringButton(rowIndex)}
                  onMouseLeave={() => setHoveringButton(-1)}
                />
              </td>
            
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    
    </div>
  );
};

export default TableAction;
