import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

// Custom React hook to compute the number of columns to show in the grid according to the screen width

const useNumCols = (uploadedFiles, allowMultipleFiles) => {

  const isScreenS = useMediaQuery({ maxWidth: 650 });
  const isScreenM = useMediaQuery({ maxWidth: 950 });
  const isScreenL = useMediaQuery({ maxWidth: 1250 });
  const isScreenXL = useMediaQuery({ maxWidth: 1550 });

  const maxCols = uploadedFiles.length + 1;

  return useMemo(() => {
    if (!allowMultipleFiles) return 1;
    if (isScreenS) return Math.min(1, maxCols);
    if (isScreenM) return Math.min(2, maxCols);
    if (isScreenL) return Math.min(3, maxCols);
    if (isScreenXL) return Math.min(4, maxCols);
    return Math.min(5, maxCols); // never show more than 5 columns
  }, [isScreenS, isScreenM, isScreenL, isScreenXL, maxCols, allowMultipleFiles]);  // call this function only when an input changes
};

export default useNumCols;
