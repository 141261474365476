import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './FileDropZone.css';

import { MdDelete } from "react-icons/md";

import pdfIcon from '../../assets/images/pdf-icon.svg';
import csvIcon from '../../assets/images/csv-icon.svg';
import useNumCols from './numColumns';
import { sPushFile } from '../../utils/sPushFile';

import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';
import SlButton from '@shoelace-style/shoelace/dist/react/button';


const FileDropZone = ({ uploadedFiles, setUploadedFiles, useCaseName }) => {

  const mimeType = (useCaseName === 'tele24') ? 'application/pdf' : 'text/csv';
  const fileExtension = (useCaseName === 'tele24') ? '.pdf' : '.csv';
  const allowMultipleFiles = true;
  const fileIcon = (useCaseName === 'tele24') ? pdfIcon : csvIcon;

  const [uploadInProgress, setUploadInProgress] = useState(false);


  const handleFileUpload = (files) => {

    if (uploadInProgress) {
        return  // prevent making two uploads at the same time
    }

    if (files && files.length > 0) {
      
      setUploadInProgress(true);

      // add entry for the new file
      const newFileEntry = {
        fileName: files[0].name,
        s3Key: '',
        uploaded: false,
        deleting: false
      };
      const newUploadedFiles = [...uploadedFiles, newFileEntry];
      setUploadedFiles(newUploadedFiles);

      setTimeout(() => {

        sPushFile(files[0], useCaseName)
          .then((uploadedKey) => {
            console.log('Uploaded file key:', uploadedKey);
            // update last entry of uploadedFiles
            const lastIndex = newUploadedFiles.length - 1;
            newUploadedFiles[lastIndex] = { ...newUploadedFiles[lastIndex], s3Key: uploadedKey, uploaded: true };
            setUploadedFiles([...newUploadedFiles]);  // always create a new array when updating a state
            setUploadInProgress(false);
          })
          .catch((error) => {
            console.error('Error during file upload:', error);
            // remove the last entry to uploadedFiles
            newUploadedFiles.pop();
            setUploadedFiles([...newUploadedFiles]);
            setUploadInProgress(false);
          });
        
      }, 300);  // simulating a brief delay
    }
  };


  const handleFileDelete = (index) => {
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles[index].deleting = true;
    setUploadedFiles(newUploadedFiles);

    setTimeout(() => {
      setUploadedFiles(newUploadedFiles => newUploadedFiles.filter((_, i) => i !== index));
    }, 200);  // wait for animation to complete
  }


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      [mimeType]: [fileExtension]
    },
    onDrop: handleFileUpload,
    multiple: allowMultipleFiles // Allow multiple file selection
  });


  return (
    <div {...getRootProps()} className="drop-file-upload" >
      <input {...getInputProps()} />
        <div className="drop-file-upload-content" style={{ gridTemplateColumns: `repeat(${useNumCols(uploadedFiles, allowMultipleFiles)}, 1fr)` }} >
          {uploadedFiles.length > 0 ? (
            <>
              {uploadedFiles.map((file, index) => (
                <div 
                  key={index} 
                  className={`file-card ${file.deleting ? 'deleting': ''}`}
                  onClick={(e) => e.stopPropagation()}  // stop the event propagation here
                >
                  <div className="file-card-top-row">
                    <img src={fileIcon} alt='File logo' height={28} width={28} />
                    <SlButton variant="danger" outline size='small' onClick={() => handleFileDelete(index)}>
                      Rimuovi
                      <MdDelete slot='suffix' />
                    </SlButton>
                  </div>
                  <span>{file.fileName}</span>
                  <div className={`upload-bar ${file.uploaded ? 'uploaded': ''}`} >
                    {file.uploaded ? (
                      <SlIcon name="check-lg" style={{ fontSize: "20px" }}></SlIcon>
                    ) : (
                      <SlSpinner className="upload-spinner" />
                    )}
                  </div>
                </div>
              ))}
              {allowMultipleFiles && (
                <div className="new-upload-card" >
                  <SlIcon name="cloud-upload" style={{ fontSize: "24pt" }}></SlIcon>
                  <span>Clicca per caricare <br /> altri files</span>
                </div>
              )}
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                <SlIcon name="cloud-upload" style={{ fontSize: "24pt" }}></SlIcon>
                <span><strong>Clicca per caricare </strong> o trascina qui<br /> solo <tt style={{ fontSize: '13pt' }}>{fileExtension}</tt> files</span>
            </div>
          )}
        </div>
    </div>
  );

};

export default FileDropZone;
