import AWS from 'aws-sdk';

import { makeToast } from './makeToast';


const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

const BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME;


const getCurrentDateTime = () => {
    const now = new Date();
  
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
};


export const sPushFile = (file, useCaseName) => {
    return new Promise((resolve, reject) => {
        const today = new Date().toISOString().split('T')[0];  // YYYY-MM-DD
        const now = getCurrentDateTime(); // full datetime

        const destinationKey = `${useCaseName}/inputs/${today}/${now}_${file.name}`;

        const params = {
            Bucket: BUCKET_NAME,
            Key: destinationKey,
            Body: file,
            ContentType: file.type,
        };

        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                makeToast(
                    `C'è stato un errore nel processo di caricamento del file, per favore controlla che il documento caricato sia conforme ai requisiti. Se necessario, contatta l'amministratore di sistema.`,
                    'warning'
                );
                reject(err);  // reject the promise in case of error
            } else {
                console.log('File uploaded successfully:', data.Location);
                resolve(destinationKey);  // resolve with the destination key
            }
        });
    });
};
