import SlAlert from '@shoelace-style/shoelace/dist/react/alert';  // keep this import even if not directly used


// Always escape HTML for text arguments!
const escapeHtml = (html)  => {
    const div = document.createElement('div');
    div.textContent = html;
    return div.innerHTML;
}

// create a toast notification as a modal
export const makeToast = (message, variant = 'primary', icon = 'info-circle', duration = 5000) => {

    if (variant === 'warning') {
        icon = 'exclamation-square'
    }

    const alert = Object.assign(document.createElement('sl-alert'), {
      variant,
      closable: true,
      duration: duration,
      innerHTML: `
        <sl-icon name="${icon}" slot="icon"></sl-icon>
        ${escapeHtml(message)}
      `
    });

    document.body.append(alert);
    return alert.toast();
}
