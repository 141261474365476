import { safeRetrieve } from './safeStoring';
import { makeToast } from './makeToast';


const DEFAULT_FORNITORE = {
    "persona_fisica": {},
    "partita_iva": {
        "denominazione": "Studio Tributario Societario",
        "comune_sede": "Milano",
        "provincia_sede": "MI",
        "indirizzo_sede": "Via Bandello 5",
        "cap_sede": "20123",
        "comune_domicilio": "Milano",
        "provincia_domicilio": "MI",
        "indirizzo_domicilio": "Via Bandello 5",
        "cap_domicilio": "20123"
    },
    "codice_fiscale": "06447960961",
    "flag_origine": "E"
};

// the info for versante depends on the codice fiscale of contribuente
const VERSANTE = {
    "07237600965": {  // Paramount
        "codice_fiscale": "CRRLRT82P29D325K",
        "tipo_versante": "1",
        "cognome": "Carrozzo",
        "nome": "Alberto Cosimo",
        "sesso": "M",
        "data_di_nascita": "29091982",
        "comune_nascita": "Dolo",
        "provincia_nascita": "VE"
    },
    "06447960961": {  // Studio Tributario Societario
        "codice_fiscale": "MRCLCU65D29F205T",
        "tipo_versante": "1",
        "cognome": "Mercaldo",
        "nome": "Luca",
        "sesso": "M",
        "data_di_nascita": "29041965",
        "comune_nascita": "Milano",
        "provincia_nascita": "MI"
    },
    "13152200963": {  // Snegaro Limited
        "codice_fiscale": "RDNDTR74D11Z135F",
        "tipo_versante": "1",
        "cognome": "Rodin",
        "nome": "Dimitri",
        "sesso": "M",
        "data_di_nascita": "11041974",
        "comune_nascita": "Urss",
        "provincia_nascita": "EE"
    },
    "13282530966": {  // Forever H
        "codice_fiscale": "SSSJPL76T50Z133N",
        "tipo_versante": "1",
        "cognome": "Sassu",
        "nome": "Jane Paule",
        "sesso": "F",
        "data_di_nascita": "10121976",
        "comune_nascita": "Svizzera",
        "provincia_nascita": "EE"
    },
    "03802730154": {  // UMI
        "codice_fiscale": "DMRDNL66E16F205A",
        "tipo_versante": "1",
        "cognome": "Demartini",
        "nome": "Daniele",
        "sesso": "M",
        "data_di_nascita": "16051966",
        "comune_nascita": "Milano",
        "provincia_nascita": "MI"
    },
    "09542840963": {  // MATHYS ORTOPEDIA SRL
        "codice_fiscale": "GRSGRG70M03F205Z",
        "tipo_versante": "1",
        "cognome": "GRASSI",
        "nome": "GIORGIO",
        "sesso": "M",
        "data_di_nascita": "03081970",
        "comune_nascita": "Milano",
        "provincia_nascita": "MI"
    },
    "02099150381": {  // FLO - FELLONI LATERAL OFFICE STP S.R.L.
        "codice_fiscale": "FLLDVD91L26C814O",
        "tipo_versante": "1",
        "cognome": "FELLONI",
        "nome": "DAVIDE",
        "sesso": "M",
        "data_di_nascita": "26071991",
        "comune_nascita": "Codigoro",
        "provincia_nascita": "FE"
    },
    "10517560156": {  // LINK ITALIA SPA
        "codice_fiscale": "MVLNRC67A27E514F",
        "tipo_versante": "1",
        "cognome": "MOVILIA",
        "nome": "ENRICO",
        "sesso": "M",
        "data_di_nascita": "27011967",
        "comune_nascita": "LEGNANO",
        "provincia_nascita": "MI"
    },
    "12233020960": {  // CROPE SRLS
        "codice_fiscale": "CIOSMN95E05A479C",
        "tipo_versante": "1",
        "cognome": "CIOE",
        "nome": "SIMONE",
        "sesso": "M",
        "data_di_nascita": "05051995",
        "comune_nascita": "ASTI",
        "provincia_nascita": "AT"
    }
}

const DEFAULT_COOBBLIGATO = {
    "codice_fiscale": "", 
    "codice_identificativo": ""
};

// the info for conto iban depends on the codice fiscale of contribuente
const CONTO_IBAN = {
    "07237600965": {  // Paramount
        "iban": "IT49Q0310401608000000821427", 
    },
    "06447960961": {  // Studio Tributario Societario
        "iban": "IT26S0623009503000063525287", 
    },
    "13152200963": {  // Snegaro Limited
        "iban": "IT57A0318501600000010301240", 
    },
    "13282530966": {  // Forever H
        "iban": "IT51R0200801628000107095347", 
    },
    "03802730154": {  // UMI
        "iban": "IT89F0356601600000125116019", 
    },
    "09542840963": {  // MATHYS ORTOPEDIA SRL
        "iban": "IT76V0100501605000000007358", 
    },
    "02099150381": {  // FLO - FELLONI LATERAL OFFICE STP S.R.L.
        "iban": "IT46N0538713003000003468872", 
    },
    "10517560156": {  // LINK ITALIA SPA
        "iban": "IT87X0503401739000000018222", 
    },
    "12233020960": {  // CROPE SRLS
        "iban": "IT30J0200801633000106391067", 
    }
};


export const callCreateTele24 = (payload) => {

    const cfContribuente = payload['contribuente']['codice_fiscale'] || '';
    const paymentDate = payload['payment_date'] || '';

    // complete payload with default values
    payload['fornitore'] = DEFAULT_FORNITORE;
    payload['versante'] = VERSANTE[cfContribuente] || {};
    payload['coobbligato'] = DEFAULT_COOBBLIGATO;
    payload['conto_iban'] = CONTO_IBAN[cfContribuente] || {};
    payload['conto_iban']['codice_fiscale'] = cfContribuente;

    const apiKey = safeRetrieve('APIKEY');

    const apiUrl = process.env.REACT_APP_API_URL || '';
    // return the fetch as a promise
    return fetch(`${apiUrl}/api/tele24/v1/create_telematic_file`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            api_key: apiKey,
            data: payload
        }),
    })
    .then((response) => {
        if (!response.ok) {
            return response.json().then((errorData) => {
                console.error(`Error in create_telematic_file response: ${response.status}`, errorData);
                makeToast(`C'è stato un errore nel processo di estrazione, per favore controlla che il documento caricato sia conforme ai requisiti. Se necessario, contatta l'amministratore di sistema.`, 'warning');
                return;
            });
        }
        return response.json();  // Proceed to parse JSON if the response is OK
    })
    .then((data) => {
        if (data && data.body) {
            return {
                'cfContribuente': cfContribuente,
                'paymentDate': paymentDate,
                's3Key': data.body
            }
        } else {
            console.warn('Unexpected response structure:', data);
            return;
        }
    })
    .catch((error) => {
        console.error('Request failed:', error);
        makeToast('Si è verificato un errore di rete. Per favore riprova più tardi.', 'warning');
        return;
    });
};
